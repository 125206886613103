body {
    background-color: #00735c; /* A nice green background */
    color: #f8f8f2;
    font-family: 'Courier New', Courier, monospace;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
    flex-direction: column;
    overflow: hidden; /* Prevents the body from scrolling */
}

#root {
    width: 100vw;
    background-color: #00735c; /* A nice green background */
    color: #f8f8f2;
    font-family: 'Courier New', Courier, monospace;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
    flex-direction: column;
    overflow: hidden; /* Prevents the body from scrolling */
}

.terminal {
    background-color: #1e1f29;
    border-radius: 8px 8px 0 0;
    width: 90%;
    height: 85%; /* Set the height of the terminal */
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    position: relative;
}

.header {
    background-color: #44475a;
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    flex-shrink: 0; /* Prevents shrinking */
}

.title {
    color: #f8f8f2;
    font-size: 14px;
    text-align: center;
    flex-grow: 1;
    margin-left: -48px; /* Center the title relative to the buttons */
}

.buttons {
    display: flex;
}

.button {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 8px;
}

.red {
    background-color: #ff5f56;
}

.yellow {
    background-color: #ffbd2e;
}

.green {
    background-color: #27c93f;
}

.nav-bar {
    background-color: #1e1f29;
    padding: 8px;
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0; /* Prevents shrinking */
}

.nav-item {
    color: #f8f8f2;
    margin-right: 5px;
    font-size: 14px;
    cursor: pointer;
}

.nav-item:hover {
    color: #8be9fd;
}

.content {
    flex-grow: 1;
    overflow-y: auto; /* Enable scrolling for the content area */
    padding: 16px;
    background-color: #282a36;
    color: #f8f8f2;
}

pre {
    margin: 0;
    white-space: pre-wrap;
    color: #f8f8f2;
}

.mode {
    color: #50fa7b;
    background-color: #44475a;
    padding: 2px 5px;
    border-radius: 4px;
}

.path {
    color: #8be9fd;
}

.pre-footer {
    background-color: #1e1f29;
    padding: 8px;
    font-size: 12px;
    color: #f8f8f2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0; /* Prevents shrinking */
}

.footer-item {
    margin-right: 10px;
    padding: 0 5px;
}

.footer-item.green {
    background-color: #50fa7b;
    color: #1e1f29;
    border-radius: 4px;
    padding: 2px 5px;
}

.footer-item.orange {
    background-color: #ffb86c;
    color: #1e1f29;
    border-radius: 4px;
    padding: 2px 5px;
}

.right-footer {
    display: flex;
    justify-content: flex-end;
}

.footer {
    background-color: #282a36;
    width: 90%;
    height: 16px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    position: relative;
    z-index: 0;
    flex-shrink: 0; /* Prevents shrinking */
}
body {
    background-color: #5a3d88; /* A nice purple background */
    color: #f8f8f2;
    font-family: 'Courier New', Courier, monospace;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
    flex-direction: column;
    overflow: hidden; /* Prevents the body from scrolling */
}

.terminal {
    background-color: #1e1f29;
    border-radius: 8px 8px 0 0;
    width: 90%;
    height: 85%; /* Set the height of the terminal */
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    position: relative;
}

.header {
    background-color: #44475a;
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    flex-shrink: 0; /* Prevents shrinking */
}

.title {
    color: #f8f8f2;
    font-size: 14px;
    text-align: center;
    flex-grow: 1;
    margin-left: -48px; /* Center the title relative to the buttons */
}

.buttons {
    display: flex;
}

.button {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 8px;
}

.red {
    background-color: #ff5f56;
}

.yellow {
    background-color: #ffbd2e;
}

.green {
    background-color: #27c93f;
}

.nav-bar {
    background-color: #1e1f29;
    padding: 8px;
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0; /* Prevents shrinking */
}

.nav-item {
    color: #f8f8f2;
    margin-right: 5px;
    font-size: 14px;
    cursor: pointer;
}

.nav-item:hover {
    color: #8be9fd;
}

.content {
    flex-grow: 1;
    overflow-y: auto; /* Enable scrolling for the content area */
    padding: 16px;
    background-color: #282a36;
    color: #f8f8f2;
}

pre {
    margin: 0;
    white-space: pre-wrap;
    color: #f8f8f2;
}

.mode {
    color: #50fa7b;
    background-color: #44475a;
    padding: 2px 5px;
    border-radius: 4px;
}

.path {
    color: #8be9fd;
}

.pre-footer {
    background-color: #1e1f29;
    padding: 8px;
    font-size: 12px;
    color: #f8f8f2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0; /* Prevents shrinking */
}

.footer-item {
    margin-right: 10px;
    padding: 0 5px;
}

.footer-item.green {
    background-color: #50fa7b;
    color: #1e1f29;
    border-radius: 4px;
    padding: 2px 5px;
}

.footer-item.orange {
    background-color: #ffb86c;
    color: #1e1f29;
    border-radius: 4px;
    padding: 2px 5px;
}

.right-footer {
    display: flex;
    justify-content: flex-end;
}

.footer {
    background-color: #282a36;
    width: 90%;
    height: 16px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    position: relative;
    z-index: 0;
    flex-shrink: 0; /* Prevents shrinking */
}

a {
  color: #00735c;
}

